import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AdminService {



  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  httpOptions2 = {
    headers: new HttpHeaders({'Content-Type': ' multipart/form-data'})
  };

  serverUrl = environment.baseUrl;
  private dataSource: BehaviorSubject<string> = new BehaviorSubject<string>('');
  data: Observable<string> = this.dataSource.asObservable();
  private dataSourceComplain: BehaviorSubject<string> = new BehaviorSubject<string>('N/A');
  dataComplain: Observable<string> = this.dataSourceComplain.asObservable();
  constructor(private http: HttpClient){  }

  sendData(id:any)
  {
    this.dataSource.next(id);
  }
  sendChatInfoToComplain(id:any)
  {
    this.dataSourceComplain.next(id);
  }


generateQRcode(data:any){
  const url = 'subAdmin/generateCode';
  return this.http.put(environment.baseUrl + url, data);
}
  getDashboard(appID: number) {
    const url = 'subAdmin/get/Admin/Dashboard/';
    return this.http.get<any[]>(environment.baseUrl + url + appID);
  }

  getVisitorLog(appId: number, uID: number) {
    const url = 'signaller/get/Signaller/Dashboard/';
    return this.http.get<any[]>(environment.baseUrl + url + appId + '/' + uID);
  }
  deleteApproval(data:any)
  {
    return this.http.put<any>(environment.baseUrl+'subAdmin/approveDeclineApartmentUser/',data);
  }
  getFilterVisitor(data: any) {
    const url = 'subAdmin/get/filter/visitorList';
    return this.http.post(environment.baseUrl + url, data, this.httpOptions);
  }

  getAdminNoticeCount(app_id:any){

    const url = 'notice/get/NotificationCount/';
    return this.http.get<any[]>(environment.baseUrl2 + url + app_id+'/0/AdminAlert');
  }

  getManagementList(appId: number) {

    const url = 'subAdmin/managementComite/management/';
    return this.http.get(environment.baseUrl + url + appId);

  }

getPollingList(appId:any){
  const url = 'polling/admin/getPollingDetails/';
  return this.http.get(environment.baseUrl2 + url + appId);
}


addPolling(data:any){
  const url = 'polling/admin/addPolling';
  return this.http.post(environment.baseUrl2 + url, data);
}

editAPolling(data:any){
  const url = 'polling/admin/editPolling';
  return this.http.post(environment.baseUrl2 + url, data);
}

getPollingDashboard(pollingId:any,appId:any){
  const url = 'polling/admin/get/showPollingDashboard/';
  return this.http.get(environment.baseUrl2 + url + pollingId+'/'+appId);
}

getEventDonationDashboard(pollingId:any,appId:any){
  const url = 'polling/admin/get/showDashboardforEventandDonation/';
  return this.http.get(environment.baseUrl2 + url + pollingId+'/'+appId);
}

getCandidateListNyPollingId(id:any){
  const url='polling/admin/getPollingCandidateDetails/';
  return this.http.get(environment.baseUrl2+url+id);
}

getUserListOfAHouse(houseId:any,floorId:any){
  const url='subAdmin/get/allResidence/';
  return this.http.get(environment.baseUrl+url+houseId+'/'+floorId);
}

addCandidateForPoll(data:any){
  const url = 'polling/admin/addCandidate';
  return this.http.post(environment.baseUrl2 + url, data);
}

editCandidateForPoll(data:any){
  const url = 'polling/admin/editcandidate';
  return this.http.post(environment.baseUrl2 + url, data);
}

  getSignallerList(appId: any) {
    const url = 'subAdmin/getSignallers/';
    return this.http.get(environment.baseUrl + url + appId);
  }

  getRegularVisitor(appID: number) {
    const url = 'subAdmin/showRegularVisitor/';
    return this.http.get(environment.baseUrl + url + appID);
  }

  addRegularVisitor(a:any,b:any)
  {
    return this.http.post<any>(environment.baseUrl + 'subAdmin/add/regularVisitor/'+a, b);
  }
  sendOtpToPh(a:any)
  {
    return this.http.get<any>(environment.baseUrl+ 'sms/OTP/'+a);
  }
  editRegularVisitor(a:any)
  {
    return this.http.put<any>(environment.baseUrl+ 'subAdmin/updateRegularVisitor',a);
  }
  getTodayVisitor(app_id:any,flag:any)
  {
    return this.http.get<any>(environment.baseUrl+'signaller/get/Signaller/Dashboard/'+app_id+'/'+flag);
  }
  getMonthFilterDash(a:any)
  {
    return this.http.post<any>(environment.newChanges+'getLedgerReportFilterByMonth',a);
  }
  getMaintenanceReport(a:any){
    return this.http.post<any>(environment.newChanges+'getLedgerReportForMaintenanceFilterByMonth',a);
  }
  getPendingApproval(appid: number) {
    const url = 'subAdmin/get/pendingApproval/';
    return this.http.get<any[]>(environment.baseUrl + url + appid);
  }
  getFacilityManager(a:number)
  {
    return this.http.get<any>(environment.baseUrl+'subAdmin/getMManagementList/'+a);
  }
  updateResidential(data: any) {
    const url = 'userNew/update/pendingApproval';
    return this.http.put(environment.baseUrl2 + url, data);
  }

  getBlockByApp(appid: number) {
    const url = 'appartment/blkByApp/';
    return this.http.get<any[]>(environment.baseUrl2 + url + appid);
  }
  exitJunctionUser(a:any)
  {
    return this.http.put<any>(environment.baseUrl +'subAdmin/update/pendingApproval',a);
  }

  exitApartmentUser(a:any)
  {
    return this.http.put<any>(environment.baseUrl +'subAdmin/leave/apartment',a);
  }

  getAllResidentInfo(appid: number) {
    const url = 'user/get/userDetailsForApart/';
    return this.http.get<any[]>(environment.baseUrl2 + url + appid);
  }
  getAllResidentInfoNew(appid: number) {
    const url = 'user/get/userDetailsForApartNew/';
    return this.http.get<any[]>(environment.baseUrl2 + url + appid);
  }

  getFloorByBlock(bid: number) {
    const url = 'appartment/floorByBlock/';
    return this.http.get<any[]>(environment.baseUrl2 + url + bid);
  }

  filterResidential(houseNo: any, fid: number) {
    const url = 'subAdmin/get/allResidence/';
    return this.http.get<any[]>(environment.baseUrl + url + houseNo + '/' + fid);
  }

  getEmergency(appid: number) {
    const url = 'subAdmin/getEmergency/';
    return this.http.get(environment.baseUrl + url + appid);
  }

  leaveApartment(id: number) {
    const url = 'subAdmin/leave/apartment/';
    return this.http.get(environment.baseUrl + url + '/' + id);
  }

  updatePassword(data: any) {
    const url = 'web/update/password';
    return this.http.post(environment.baseUrl + url, data);
  }

  getAdminProfile(id: number) {
    const url = 'subAdmin/showAdminProfile/';
    return this.http.get(environment.baseUrl + url + id);
  }

  getBankDetails(appid: number) {
    const url = 'subAdmin/get/bankDetails/';
    return this.http.get(environment.baseUrl + url + appid);
  }

  updateBankDetails(data: any) {
    const url = 'subAdmin/update/bankDetails';
    return this.http.put(environment.baseUrl + url, data);
  }

  getNotification(appID: number) {
    const url = 'fcm/get/fcmNoticeforAdmin/';
    return this.http.get<any[]>(environment.baseUrl + url + appID);
  }

  addNotice1(data: any) {
    const url = 'notice/add/Notice';
    return this.http.post(environment.baseUrl2 + url, data);
  }
  addNotice2(data: any) {
    const url = 'notice/add/NoticeforApartment';
    return this.http.post(environment.baseUrl2 + url, data);
  }
  addNotice3(data: any) {
    const url = 'notice/add/NoticeforManagementCommiteeUser';
    return this.http.post(environment.baseUrl2 + url, data);
  }
  addNotice4(data:any){
    const url = 'notice/add/NoticeforApartmentOwner';
    return this.http.post(environment.baseUrl2 + url, data);
  }
  getNoticeTopic(appID: number) {
    const url = 'fcm/get/NotificationTopics/';
    return this.http.get<any[]>(environment.baseUrl + url + appID);
  }

  getQueryList(appID: number) {
    const url = 'fcm/get/fcmAlertsforAdmin/';
    return this.http.get<any[]>(environment.baseUrl + url + appID);
  }

  getAdminList(appID: number) {
    const url = 'subAdmin/getAdminList/';
    return this.http.get<any[]>(environment.baseUrl + url + appID);
  }

  getAllAssets() {
    const url = 'asset/allAsset';
    return this.http.get<any[]>(environment.baseUrl2 + url );
  }

  getAllAssetsById(app_id:any) {
    const url = 'asset/allAssetManagementListByAdmin/';
    return this.http.get<any[]>(environment.baseUrl2 + url + app_id);
  }

  editAsset(data: any) {
    const url = 'asset/editAssetManagement';
    return this.http.post(environment.baseUrl2 + url, data);
  }

  addAsset(data: any) {
    const url = 'assetNew/add/addAssetManagement';
    return this.http.post(environment.assetUrl + url, data);
  }

  getNotice(appID: number) {
    const url = 'notice/get/NoticeforCustomer/';
    return this.http.get<any[]>(environment.baseUrl2 + url + appID);
  }
  editNotice(data: any) {
    const url = 'notice/edit/Notice/';
    return this.http.put(environment.baseUrl2 + url, data);
  }

  checkDuplicate(data: any) {
    const url = 'lr/edit/profile/checkDuplicateAdminProfile';
    return this.http.put(environment.baseUrl + url, data).pipe(tap(res => {
    }, err => {
    }));
  }
  checkDuplicatePhone(data:any){
    return this.http.post<any>(environment.baseUrl+"user/checkDuplicate/",data);
  }
  checkDuplicateAccount(accountno,appid:any)
  {
    return this.http.get<any>(environment.newChanges+'checkDuplicate/'+accountno+'/'+appid);
  }


  getGraphDataBySingleDate(date: any,app_id:any){
    const url ='getheadCountPerHourPerDay/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date+'/'+app_id);
  }

  getGraphdataMFBySingleDate(date: any,app_id:any){
    const url ='getmlFMLCountPerHourPerDay/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date+'/'+app_id);

  }

  getGraphdataMFByTwoDate(date1: any, date2 : any,app_id:any){
    const url ='getMVsFCountPerMonth/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date1 +'/'+date2+'/'+app_id);

  }

  ageAnalyticsBySingleDate(date,app_id:any){
    const url ='getAgeCompareCountPerHourPerDay/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date+'/'+app_id);

  }

  heVsSheBySingleDate(date : any,app_id:any){
    const url ='getmlFMLCountPerHourPerDay/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date+'/'+app_id);

  }

  getHeadCountOfMonth(date1:any,date2:any,app_id:any){
    const url ='getHeadCountPerMonth/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date1+ '/'+date2+'/'+app_id);

  }


  getMaleVsFemaleOfMonth(date1:any,date2:any,app_id:any){
    const url ='getMVsFCountPerMonth/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date1+ '/'+date2+'/'+app_id);

  }


  getAgeCompareOfMonth(date1:any,date2:any,app_id:any){
    const url ='getAgeCompareCountPerMonth/'
    return this.http.get<any[]>(environment.mallGraphUrl + url + date1+ '/'+date2+'/'+app_id);

  }




getAllHouseAllDue(app_id:any,due_date:any){
  const url ='getAllHouseDue/';

  return this.http.get<any[]>(environment.houseDueUrl + url + app_id+ '/'+due_date);

}
getAllHouseAllDue1(app_id:any,due_date:any){
  const url ='getAllHouseDue/';
  // due_date='2020-12-31'; // hard coded due to insufficient data for last month
  // app_id ='1002';
  return this.http.get<any[]>(environment.houseDueUrl1+ url + app_id+ '/'+due_date);
  //http://dev01.ohhpro.com/ohhpro_admin/admin/admin/getAllHouseDue/1001/2020-07-31
}

updatePay(formdata: any){
  const url ='updatePay';
  // return this.http.get<any[]>(environment.houseDueUrl + url);
  return this.http.post(environment.houseDueUrl + '' + url, formdata);
  //http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updatePay
}
updatePay2(formdata: any)
{
  const url = 'updatePayNew';
  return this.http.post<any>(environment.houseDueUrl+''+ url, formdata);
}
updatePay1(formdata: any){
  const url ='updatePay';
  // return this.http.get<any[]>(environment.houseDueUrl + url);
  return this.http.post(environment.houseDueUrl1 + '' + url, formdata);
  //http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updatePay
}
addHouseFromExcelTable(body : any ){
  return this.http.post<any>('http://localhost:8080/admin/apartment/add/block',body);
}
generateInvoice(app_id:any,due_date:any, data:any){
  const url ='updateMaintenance/';
  //due_date='2020-12-31'; // hard coded due to insufficient data for last month
  // app_id ='1002';
  console.log("procedure");
    return this.http.post<any[]>(environment.houseDueUrl + url + app_id+ '/'+due_date,data);
  //http://dev01.ohhpro.com/ohhpro_admin/admin/admin/getAllHouseDue/1001/2020-07-31
  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updateMaintenance/1001/2021-02-28
}
generateInvoice1(app_id:any,due_date:any){
  const url ='updateMaintenance/';
  //due_date='2020-12-31'; // hard coded due to insufficient data for last month
  // app_id ='1002';
  console.log("procedure");
    return this.http.get<any[]>(environment.houseDueUrl1 + url + app_id+ '/'+due_date);
  //http://dev01.ohhpro.com/ohhpro_admin/admin/admin/getAllHouseDue/1001/2020-07-31
  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updateMaintenance/1001/2021-02-28
}
getAllHousePayTemplate(app_id:any,due_date:any,frequency_type:any){
 const url ='getHouse/';
  // due_date='2020-12-31'; // hard coded due to insufficient data for last month
  // app_id ='1002';
  return this.http.get<any[]>(environment.houseDueUrl + url + app_id+ '/'+due_date +'/'+frequency_type);


  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/getHouse/1001/2020-12-3
}
getAllHousePayTemplate1(app_id:any,due_date:any){
  const url ='getHouse/';
   // due_date='2020-12-31'; // hard coded due to insufficient data for last month
   // app_id ='1002';
   return this.http.get<any[]>(environment.houseDueUrl1 + url + app_id+ '/'+due_date);


   // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/getHouse/1001/2020-12-3
 }
upadateApartmentTax(body : any ){

  const url ='apart/pay/updateAppPercentNew';
  return this.http.post<any>(environment.baseUrl2 + url ,body);

  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updateAppPercent

}
upadateApartmentTax1(body : any ){

  const url ='updateAppPercent/';
  return this.http.post<any>(environment.houseDueUrl1 + url ,body);

  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/admin/updateAppPercent

}
cashPay(body : any ){

   const url ='cash/maintenanceAmount';
  return this.http.post<any>(environment.razorpayUrl +url ,body);



}
updateLateFee(body : any ){
  const url ='updateMaitenanceLateFee';

  return this.http.post<any>(environment.razorpayUrl +url ,body);

}
getApartmentTax(app_id: any){
  const url ='apart/pay/appPercentNew/';
  return this.http.get<any[]>(environment.baseUrl2+url+app_id);

}
addHouse(data: any) {
  const url = 'appoff/addHouse';
  return this.http.post(environment.baseUrl1 + url, data);
}
getHouseByFloor(FId: any) {
  const url = 'appartment/houseByFloor/';
  return this.http.get<any[]>(environment.baseUrl2 + '' + url + FId);
}
getRestHouseByFloor(FId: any) {
  const url = 'appartment/get/House_restrict/';
  return this.http.get<any[]>(environment.baseUrl2 + '' + url + FId);
}
listOfQrCodeByappId(id:any){
  const url = 'subAdmin/getActiveJunctionCodeForApartments/';
  return this.http.get<any[]>(environment.baseUrl + '' + url + id);
}
activeHouse(data: any) {
  const url = 'appoff/updateHouseActive/';
  return this.http.put(environment.baseUrl1 + '' + url + data.id, data);
}

addFloor(data: any) {
  const url = 'appoff/addFloor';
  return this.http.post(environment.baseUrl1 + '' + url, data);
}
getBalance(a:number,b:number,c:number,d:number,frequency_type:number)
{
  return this.http.get<any>(environment.razorpayUrl+'maintenanceInvoiceNew/'+a+'/'+b+'/'+c+'/'+d+'/'+frequency_type);
}
getFloorById(blkID: any) {
  const url = 'appoff/floor/';
  return this.http.get<any[]>(environment.baseUrl1 + url  + blkID);
}
getHouseById(appId:number)
{
  return this.http.get<any>(environment.baseUrl2+'appartment/getHouseById/'+appId);
}
activateFloor(fID: number, editdata: any) {
const url = 'appoff/updateFloorActive/';
return this.http.put(environment.baseUrl1 + url + fID,  editdata);
}
getActiveFloorByBlock(blk_ID: number) {
  const url = 'signaller/get/floor/';
  return this.http.get<any>(environment.baseUrl + url + blk_ID);
}

addBlock(data: any) {

  const url = 'appoff/addBlock';
  return this.http.post(environment.baseUrl1 + url, data);
}
getBlockById(appID: any) {
  console.log("appID=",appID);
  return this.http.get<any[]>(environment.baseUrl2 +'appartment/block/'+appID);
}
getBlockByappId(appID: number) {
  console.log("appID=",appID);
  const url = 'signaller/get/block/';
  return this.http.get<any[]>(environment.baseUrl + url + appID);
}
submitBlockdetails(b:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateBlock/',b);
}
submitFloorDetails(b:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateFloor/',b);
}
submitHouseDetails(b:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateHouse',b);
}
deleteBlock(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateBlock/',a);
}
deleteFloor(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateFloor/',a);
}
deleteHouse(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateHouse',a);
}
getAllFloorDetails(a:any)
{
  return this.http.get<any>(environment.baseUrl2+'appartment/floorByApp/'+a);
}
activeBlock(data: any) {
  const url = 'appoff/updateBlockActive/';
  return this.http.put(environment.baseUrl1 + '' + url + '' + data.blk_id , data);
}
getActiveBlockByAppOff(appId: number) {
  const url = 'appartment/blkByApp/';
  return this.http.get<any>(environment.assetUrl + url + appId);
}


getAreById(Cid: any) {
  const url = 'location/allArea/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + Cid );
 }
 addArea(data: any) {
   const url = 'location/addareas';
   return this.http.post(environment.baseUrl1 + '' + url, data, this.httpOptions);
 }
 activeArea(data: any) {
   const url = 'location/updateAreaActive/';
   return this.http.put(environment.baseUrl1 + '' + url + '' + data.area_id , data);
 }
 getPinByArea(aID: number) {
   const url = 'location/pinbyid/';
   return this.http.get(environment.baseUrl1 + '' + url + aID);
 }
 getActiveAreaByID(cityId: number) {
   const url = 'location/activeArea/';
   return this.http.get<any[]>(environment.baseUrl1 + '' + url + cityId);
 }


 getAreaById(Sid: any) {
  //location/activeArea
  const url = 'location/activeArea/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + Sid);
}
getCityById(Sid: any) {
  const url = 'location/activeCity/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + Sid);
  console.log("get city Url : "+environment.baseUrl1+ '' + url + Sid);
}
addCity(data: any) {
  const url = 'location/addcities';
  console.log(data);
  return this.http.post<any>(environment.baseUrl1 + '' + url, data, this.httpOptions);
}
activeCity(data: any) {
  const url = 'location/updateCityActive/';
  return this.http.put(environment.baseUrl1 + '' + url + '' + data.city_id , data );
}
getAllCities() {
  const url = 'location/allCity';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}
getActiveCityById(aId: number) {
  const url = 'location/activeCity/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + aId);
}
getAllActiveCity() {
  const url = 'location/activeCity';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}

getAllCountry(): Observable<any[]> {
  const url = 'location/allCountry';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}
getActiveCountry(): Observable<any[]> {
  const url = 'location/country';
  return this.http.get<any[]>(environment.baseUrl2 + '' + url);
}
activeCountry(data: any) {
  const url = 'location/updateCountry/';
  return this.http.put(environment.baseUrl1 + '' + url + '' + data.country_id, data);
}


getAllstate(): Observable<any[]> {
  const url = 'location/allState';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
  }
getActiveStateById(cId: number) {
  const url = 'location/country/state/';
  return this.http.get<any[]>(environment.baseUrl2 + '' + url + cId);
}
activeState(data: any) {
  const url = 'location/updateState/';
  return this.http.put(environment.baseUrl1 + '' + url + '' + data.state_id , data );
}
getStateById(Cid: any): Observable<any[]> {
  const url = 'location/allState/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + '' + Cid);
  }
getActiveState(appid:any) {
  const url = 'location/country/state/';
  return this.http.get<any[]>(environment.baseUrl2 + url + appid);
}

addApartment(data: any) {
  const url = 'appoff/addAppOfc';
  return this.http.post(environment.baseUrl1 + '' + url, data, this.httpOptions);
}
getVisitorPurpose()
{
  return this.http.get<any>(environment.baseUrl+'subAdmin/visitingPurpose');
}
getPinCode(Aid: any) {
  const url = 'location/pinbyid/';
  return this.http.get(environment.baseUrl1 + '' + url + Aid);
}

checkDuplicateAppEmail(data: any) {
  const url = 'user/checkDuplicate/';
  return this.http.post(environment.baseUrl2+ url, data).pipe(tap(res => {

  }, err => {

  }));
}
sendEmailOTP(email: string) {
  const url = 'lr/emailotp';
  let mail = { 'email': email };
  return this.http.post(environment.baseUrl1 + '' + url, mail).pipe(tap(res => {
    // this.loader.stop();
  }, err => {
    console.log(err);
    // this.loader.stop();
  }));
}
veryfyMailOTP(admin: number) {
  const url = 'appoff/verifyEmail/';
  return this.http.put(environment.baseUrl1 + '' + url + admin, '');
}
activeApartment(data: any) {
  const url = 'appoff/updateAppOffActive/';
  return this.http.put(environment.baseUrl1 + '' + url + data.app_id, data);
}
getApartByAreaId(areaId: any) {
  const url = 'appoff/appbyarea/';
  return this.http.get(environment.baseUrl1 + '' + url + areaId);
}
getOfficeByAreaId(areaId: number) {
  const url = 'appoff/officebyarea/';
  return this.http.get(environment.baseUrl1+ '' + url + areaId);
}
getMallByAreaId(areaId: number ) {
  const url = 'appoff/activeMall/';
  return this.http.get(environment.baseUrl1 + '' + url + areaId );
}
getActiveApartmentByArea(aID: number) {
  const url = 'appoff/activeApartment/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + aID);
}
getActiveOfficeByArea(aID: number) {
  const url = 'appoff/activeOffice/';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url + aID);
}
getActiveOfficeByArea1(aID: number, app_type:any) {
  const url = 'appoff/activeOffice/';
  return this.http.get<any[]>(environment.baseUrl + '' + url + aID + '/'+app_type);
}
getAllActiveApartment() {
  const url = 'appoff/allActiveApartment';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}
getAllActiveOffice() {
  const url = 'appoff/allActiveOffice';
  return this.http.get<any>(environment.baseUrl1 + '' + url);
}
updateAppSubscription(appID: number, data: any) {
  const url = 'appoff/updateApartmetSub/';
  return this.http.put(environment.baseUrl1 + url + appID, data);
}
updateOffSubscription(offID: number, data: any) {
  const url = 'appoff/updatOfficeSub/';
  return this.http.put(environment.baseUrl1 + url + offID, data);
}
getAppbyFilter(areaID: number, sub: number) {
  const url = 'appoff/ApartmentByArea/';
  return this.http.get<any>(environment.baseUrl1 + url + areaID + '/' + sub);
}
getOffByFilter(areaID: number, sub: number) {
  const url = 'appoff/OfficeByArea/';
  return this.http.get<any>(environment.baseUrl1 + url + areaID + '/' + sub);
}
getMallByFilter(areaID: number, sub: number) {
  const url = 'appoff/MallByArea/';
  return this.http.get<any>(environment.baseUrl1 + url + areaID + '/' + sub);
}
getActiveSubscription() {
  const url = 'appoff/getActiveSubscription';
  return this.http.get<any[]>(environment.baseUrl1 + url);
}
activateAdmin(active_status: any, app_id: number) {
  const url = 'appoff/updateAppOffAdminActive/';
  console.log('hi ' + active_status);
  return this.http.put(environment.baseUrl1 + url + app_id, active_status);
}

getAttendance(data: any) {
  const url = 'subAdmin/getAttendancePerDepartment';
  return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
}


showIncomeExpense(data:any){
  const url = '/apart/pay/get/allIncomeExpanseDetailsFilter';
  return this.http.post(environment.baseUrl2 + '' + url, data, this.httpOptions);
  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/allIncomeExpanseDetailsFilter
}

showIncomeExpenseYearly(app_id:any, blk_id:any,year:number){
  const url = '/apart/pay/get/totalMaintenanceForApartNew';
  return this.http.get(environment.baseUrl2 + '' + url+'/'+app_id+'/'+blk_id+'/'+year);

  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/totalMaintenanceForApart/1001/1003/2021-01-31/2021-12-31

}

getApartmentPayStaticItem() {
  const url = 'apartmentPayStatic/get/allApartmentPayStaticItems';
  return this.http.get(environment.houseDueUrl1 + url);
  // return this.http.get<any>('http://dev01.ohhpro.com:9080/ohhpro_admin/admin/apartmentPayStatic/get/allApartmentPayStaticItems');
}


insertIncomeExpense(data: any) {
  const url = 'apart/pay/add/apartmentPayStaticItem';
  return this.http.post(environment.baseUrl2 + url, data);
  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/add/apartmentPayStaticItem
}
getMonthlyTotalIncomeExpenseByYear(data: any){
  const url = 'apart/pay/get/getTotalIncomeExpanse';
  console.log("jyoti=",data);
  return this.http.post(environment.baseUrl2 + url, data);

  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/getTotalIncomeExpanse
}

getMonthlyTotalMaintenanceByYear(data: any){
  const url = 'apart/pay/get/getTotalMainIncome';
  return this.http.post(environment.baseUrl2 + url, data);

  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/getTotalMainIncome

}

getTotalMaintenanceByYear(data: any){
  const url = 'apart/pay/get/getTotalYearlyMaintIncome';
  return this.http.post(environment.baseUrl2+ url, data);

  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/getTotalYearlyMaintIncome

}
getTotalIncomeExpenseByYear(data: any){
  const url = 'apart/pay/get/getTotalYearlyIncomeExpanse';
  return this.http.post(environment.baseUrl2+ url, data);

  // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/get/getTotalYearlyIncomeExpanse
}


count(app_id:number){
     const url ='apart/pay/getFutureInvoiceGeneration/';
  return this.http.get<any>(environment.baseUrl2+ url + app_id);
  //http://dev01.ohhpro.com/ohhpro_admin/admin/admin/getAllHouseDue/1001

  }
  GetAssets(app_id:number){
 return this.http.get<any>(environment.ohhproAsset+'getAssetDetailsNew/'+app_id);
 }
 AddAssets(addForm: any){
  const url = 'assetNew/add/addAssetManagement';
  return this.http.post(environment.assetUrl+ url,addForm);
  //http://dev01.ohhpro.com:9080/ohhpro/rest/assetNew/add/addAssetManagement
 }
 getDashBoardCount(a:any){
   return this.http.get<any>(environment.baseUrl2+'appartment/getDashboardCount/' + a);
 }

getAllUser(a:any){
  return this.http.get<any>(environment.baseUrl2+'appartment/getHouseById/' + a);
}

getAllAssetDetailsByCategory(a:any){
  console.log(a);
  return this.http.get<any>(environment.newChanges+'admin/asset/getAllAssetDetailsByCategory/'+a);
}
getuserDetails(a:any)
{
  return this.http.get<any>(environment.baseUrl2+'appartment/getHouseById/' + a);
}
getDetailsOwner(a:any)
{
  return this.http.get<any>(environment.baseUrl2+'tenant/getAdminApproval/'+a);
}
getExcelSheet(a:any)

{
  return this.http.get<any>(environment.baseUrl2+'appartment/getHouseMaintenanceListByAppId/'+a);
}
getBlockExcel(a:any)
{
  return this.http.get<any[]>(environment.baseUrl +'signaller/get/block/'+ a);
}
editHouse(a:any)
{
  return this.http.post<any>(environment.baseUrl2+'appartment/updateHouseMaintainance',a);
}
editBlock(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateBlock/', a);
}
getFloorExcel(a:any)
{
  return this.http.get<any>(environment.baseUrl2 +'appartment/floorByApp/'+ a);
}
getmanagementCategory()
{
  return this.http.get<any>(environment.baseUrl+'subAdmin/managementComite/allManagementCategory');
}
getUserByhouseId(house,flr)
{
  return this.http.get<any>(environment.baseUrl+'subAdmin/get/allResidence/'+house+'/'+flr);
}
addManagementCommittee(formdata)
{
  return this.http.post<any>(environment.baseUrl+'subAdmin/managementComite/add/createManagement',formdata);
}
editFloor(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateFloor/',a);
}

getHouseExcel(a:any)
{
  return this.http.get<any>(environment.baseUrl2+'appartment/getHouseById/'+ a);
}
editHouses(a:any)
{
  return this.http.put<any>(environment.baseUrl2+'appartment/updateHouse/', a);
}
deleteSignal(a:number)
{
  return this.http.put<any>(environment.baseUrl+'subAdmin/deleteSignallers',a);
}
activeSignal(a:any)
{
  return this.http.put<any>(environment.baseUrl+'subAdmin/activateSignallers',a);
}
postAddForm(a:any)
{
  return this.http.post<any>(environment.baseUrl2+'appartment/add/AppConfig',a);
}
postGateDetails(a:any)
{
  return this.http.post<any>(environment.baseUrl2+'appartment/edit/editAppConfig',a);
}
postLiftDetails(a:any)
{
  return this.http.post<any>(environment.baseUrl2+'appartment/edit/editAppConfig',a);
}
getBankData(a:number,b:number,c:string)
{
  return this.http.get<any>(environment.newChanges+'ledger/getBankBalance/'+b+'/'+a+'/'+c);
}
getBankData1(a:number,b:number)
{
  return this.http.get<any>(environment.newChanges+'ledger/getBankBalanceDetails/'+b+'/'+a);
}
getAccountTransaction(a:number,b:number,c:number)
{
 return this.http.get<any>(environment.newChanges+'getBankTransactionById/'+a+'/'+b+'/'+c);
}

addAssetCategory(data:any){
  return this.http.post<any>(environment.ohhproAsset+'addAssetCategory',data);
}
addAssetDetails(data){
  console.log("in=",data);
  return this.http.post<any>(environment.newChanges+'addAssetDetails',data);
}



addAppartmentVendor(a:any){
  console.log(a);
  return this.http.post<any>(environment.newChanges+'vendor/addApartmentVendor',a);
}
getVendor(appid:number,year:number,month:number,currentyear:number){
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorPayDetailsHomeScreen/'+appid+'/'+year+'/'+month+'/'+currentyear);
}
getVendorDetails(a:any){
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorDetails/'+a);
}
getVendorPayDetails(a:any,b:number){
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorPayDetails/'+a+'/'+b,this.httpOptions);
}
getVendorPayDetails1(a:any,b:number){
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorPayDetails/'+a+'/'+b);
}

getInfraDetails(a:number,b:string)
{
  return this.http.get<any>(environment.baseUrl+'location/appartmentConfig/'+a+'/'+b);
}
postData(a:any)
{
  return this.http.post<any>(environment.baseUrl+'subAdmin/add/MaintenanceManager',a);
}
postData1(a:any)
{
  return this.http.post<any>(environment.baseUrl+'subAdmin/add/signaller',a);
}
postData2(a:any,b:any)
{
  return this.http.put<any>(environment.baseUrl+'subAdmin/updateSignallers/'+b,a);
}
verify(a:any)
{
  return this.http.post<any>(environment.baseUrl+'subAdmin/verify/phone',a);
}
getUserData(a:any)
{
  return this.http.get<any>(environment.baseUrl+'Signaller/profile/get/'+a);
}
editFaciltyData(a:any,b:any)
{
  return this.http.put<any>(environment.baseUrl+'subAdmin/updateMM/'+b,a);
}


addBankDetail(a:any)
{
  return this.http.post<any>(environment.razorpayUrl+'add/addApartmentBank',a);
}
getAccountData(appid:any,year:any)
{
  return this.http.get<any>(environment.newChanges+'ledger/getBankBalance/'+appid+'/'+year);
}
addAccountData(a:any)
{
  return this.http.post<any>(environment.razorpayUrl+'add/addApartmentBankDetails',a);
}
getAccountData1(a:any){
  return this.http.get<any>(environment.razorpayUrl+'get/getApartmentBankList/'+a);
}
getAccountDetails(a:any)
{
 return this.http.get<any>(environment.razorpayUrl+'get/getApartmentBankDetailsList/'+a);
}
grantPermission(a:any)
{
  return this.http.post<any>(environment.razorpayUrl+'edit/editApartmentBank',a);
}
exitBank(a:any)
{
 return this.http.post<any>(environment.razorpayUrl+'delete/deactivateApartmentBank',a);
}

addVendorPayment(){
  return this.http.get<any>(environment.newChanges+'vendor/addApartmentVendorPay');
}
addVendorBank(a:any){
  return this.http.put<any>(environment.newChanges+'vendor/editVendorBankdetails',a);
}
editVendorDetails(a:any){
  return this.http.put<any>(environment.newChanges+'vendor/editApartmentVendor',a);
}

editAdminDetails(a:any)
{
   return this.http.post<any>(environment.baseUrl+'subAdmin/updateUserToAdmin',a);
}
addPay(a:any){
  return this.http.post<any>(environment.newChanges+'vendor/addApartmentVendorPay',a);
}
editVendorPay(a:any){
  return this.http.put<any>(environment.newChanges+'vendor/editApartmentVendorPay',a);
}
getVendordetails(a:number,b:number)
{
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorPayDetailsHomeScreenDownload/'+a+'/'+b);
}
EditAssetCategory(a:any){
  console.log("inside Service=>",a);
  return this.http.post<any>(environment.ohhproAsset+'editAssetCategory',a);
}
editVendorBank(a:any){
  return this.http.put<any>(environment.newChanges+'vendor/editVendorBankdetails',a);
}
getVendorDetailsList(a:any){
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorList/'+a);
}

editAssetDetails(editAssetForm:any){
  console.log("hi");
  return this.http.post<any>(environment.ohhproAsset+'editAssetDetails',editAssetForm);
}
getAssetMaintainanceDetails(asset_details_id:number){
  console.log("in service")
  return this.http.get<any>(environment.ohhproAsset+'admin/asset/getAssetMaintenanceDetails/'+asset_details_id);
}
getAssetDetailsByAssetDetailsId(asset_details_id:number){
  return this.http.get<any>(environment.ohhproAsset+'admin/asset/getAssetDetailsById/'+asset_details_id);
}
getAppartmentInventryTracker(app_id:number,year:number){
  console.log("hiii app_id=",app_id,year);
  return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventoryTracker/'+app_id+'/'+year);
}
getApartmentLedgerGroup(year:number,app_id:number){
  console.log("in service");
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerGroup/'+year+'/'+app_id);

}
getIncome(year:number,app_id:number){
  console.log("in service");
  return this.http.get<any>(environment.newChanges+'ledger/getIncome/'+year+'/'+app_id);
}
getExpense(year:number,app_id:number){
  console.log("in service");
  return this.http.get<any>(environment.newChanges+'ledger/getExpense/'+year+'/'+app_id);
}
getVendorExpense(year:number,app_id:number)
{
 return this.http.get<any>(environment.newChanges+'ledger/getVendorForDashBoard/'+year+'/'+app_id);
}
getGraphData(a:number)
{
  return this.http.get<any>(environment.newChanges+'ledger/getIncomeGraph/'+a);
}
getGraphData1(a:number)
{
  return this.http.get<any>(environment.newChanges+'ledger/getExpenseGraph/'+a);
}
addApartmentInventoryGroup(group:any){
  return this.http.post<any>(environment.newChanges+'inventory/addApartmentInventoryGroup',group);
}
addApartmentInventory(InventoryForm:any){
  return this.http.post<any>(environment.newChanges+'inventory/addApartmentInventory',InventoryForm);
}
getApartmentInventoryGroup(app_id:number){
return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventoryGroup/'+app_id);
}
addApartmentInventoryDetails(InventoryDetailsForm:any){
  return this.http.post<any>(environment.newChanges+'inventory/addApartmentInventoryDetails',InventoryDetailsForm);
}
getApartmentInventory(app_id:number){
  return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventory/'+app_id);
}
getApartmentInventoryDetails(app_id:number){

  return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventoryDetailsByApp/'+app_id);
}
getApartmentInventoryDetails1(app_id:number,invent:number){

  return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventoryDetailsByInventoryDetails/'+app_id+'/'+invent);
}
getApartmentInventoryGroupList(app_id:number){
  return this.http.get<any>(environment.newChanges+'inventory/getApartmentInventoryGroupByApp/'+app_id);
}
getApartmentAssetDetailsByGroup(app_id:number,flag:string){
  return this.http.get<any>(environment.newChanges+'getApartmentAssetDetailsByGroup/'+app_id+'/'+flag);
}

editApartmentInventoryGroup(EditGroupForm: any){
  console.log("inside Service=",EditGroupForm)
  return this.http.post<any>(environment.newChanges+'inventory/editApartmentInventoryGroup',EditGroupForm);
}
editApartmentInventory(EditInventoryForm:any){
  return this.http.post<any>(environment.newChanges+'inventory/editApartmentInventory',EditInventoryForm);
}
editApartmentInventoryDetails(EditInventoryDetailsForm:any){
  return this.http.post<any>(environment.newChanges+'inventory/editApartmentInventoryDetails',EditInventoryDetailsForm);
}
getAssetCategoryByApp(app_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAssetCategoryByApp/'+app_id);
}

getIndivisualAsset(assetData:any)
{
  return this.http.post<any>(environment.newChanges+'getApartmentAssetDetailsByFilter/',assetData);
}
postAutoGeneratedInvoice(a:any)
{
  return  this.http.post<any>(environment.newChanges+'admin/updateGenerateInvoice',a);
}
getinvId(a:any)
{
  return this.http.get<any>(environment.razorpayUrl+'getApartmentPayDetails/'+a);
}
postEdit(a:any)
{
  return this.http.post<any>(environment.razorpayUrl+'updateMaitenancePayDetails',a);
}
getIndivisualPayInfo(a:any)
{
return this.http.get<any>(environment.razorpayUrl+'getApartmentPayAdjustmentDetails/'+a);
}
getMainData(year,appid)
  {
    return this.http.get<any>(environment.newChanges+'ledger/getMaintenanceIncome/'+year+'/'+appid);
  }
getonetimeVendordetails(appid:any,type:any)
{
  return this.http.get<any>(environment.newChanges+'vendor/getApartmentVendorbyPaymentType/'+appid+'/'+type);
}
getGeneralLedger1(app_id:number,Start,End){
  return this.http.get<any>(environment.newChanges+'getLedgerReportFilter/'+app_id+'/'+Start+'/'+End);
}
getApartmentLedgerEntryByID1(appId,Start,End)
{
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerEntryByAppFilter/'+appId+'/'+Start+'/'+End);
}
PostEmergency(formdata)
{
 return this.http.post<any>(environment.emergency+'visitor/updateEmergencyContact',formdata);

}
getApartmentLedgerGroupWithLedger1(app_id:number,Start,End)
{
  return this.http.get<any>(environment.newChanges+'getTrialBalanceReportFilter/'+app_id+'/'+Start+'/'+End);
}
getAssetBooking(app_id:number,year:number){
  return this.http.get<any>(environment.razorpayUrl2+'user/getAssetBookingDetailsByAdmin/'+app_id+'/'+year);
}
paymentBooking(FormGroup:any){
  return this.http.post<any>(environment.razorpayUrl2+'update/markAsPaid',FormGroup);
}
getledgerGroup(app_id:number){
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerGroupByApp/'+app_id);
}
getApartmentLedger(appId:number){
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerByApp/'+appId);
}
getApartmentLedger1(groupid,appId)
{
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerByLedgerGroupId/'+groupid+'/'+appId);
}
getApartmentLedgerEntryByID(appId:number){
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerEntryByApp/'+appId);
}
addApartmentLedgerGroup(addLedgerGroup:any){
  console.log("addLedgerGroup",addLedgerGroup);
  return this.http.post<any>(environment.newChanges+'ledger/addApartmentLedgerGroup',addLedgerGroup);
}
editApartmentLedgerGroup(editLedgerGroup:any){
  console.log("editLedgerGroup",editLedgerGroup);
  return this.http.post<any>(environment.newChanges+'ledger/editApartmentLedgerGroup',editLedgerGroup);
}
addApartmentLedger(ledger:any){
  console.log("addLedgerGroup",ledger);
  return this.http.post<any>(environment.newChanges+'ledger/addApartmentLedger',ledger);
}
editApartmentLedger(editLedger:any){
  console.log("editLedger",editLedger);
  return this.http.post<any>(environment.newChanges+'ledger/editApartmentLedger',editLedger);
}
addApartmentLedgerEntry(journalEntry:any){
  console.log("inside service",journalEntry);
  return this.http.post<any>(environment.newChanges+'ledger/addApartmentLedgerEntry',journalEntry);
}
editApartmentLedgerEntry(editJournalEntry:any){
  console.log("addLedgerGroup",editJournalEntry);
  return this.http.post<any>(environment.newChanges+'ledger/editApartmentLedgerEntry',editJournalEntry);
}

getApartmentLedgerGroupWithLedger(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'getTrialBalanceReport/'+app_id+'/'+year);
}

getApartmentLedgerEntry(ledger_id:number){
  console.log("hi2",ledger_id)
  return this.http.get<any>(environment.newChanges+'ledger/getApartmentLedgerEntry/'+ledger_id);
}
getAssetDashboard(app_id:number,flag:string){
  return this.http.get<any>(environment.newChanges+'getAssetDashboard/'+app_id+'/'+flag);
}
getApartmentAssetDetailsByCategory(a:any){
  return this.http.post<any>(environment.newChanges+'getApartmentAssetDetailsByCategory',a);
}
getInventoryDetailsByGroup(app_id:number,inventory_group_id:number){
  return this.http.get<any>(environment.newChanges+'inventory/getInventoryDetailsByGroup/'+app_id+'/'+inventory_group_id);
}
getInventoryCheck(inventory_group_id:number,inventory_id:number){
  return this.http.get<any>(environment.newChanges+'ledger/getInventoryCheck/'+inventory_group_id+'/'+inventory_id);
}
editAssetDetailsInvoicePic(InvoiceGroup:any){
  console.log("===================hiiiiiiiiiiiiiiii")
  return this.http.post<any>(environment.ohhproAsset+'editAssetDetailsInvoicePic',InvoiceGroup);
}
editAssetDetailsPhoto(ImageGroup:any){
  return this.http.post<any>(environment.ohhproAsset+'editAssetDetailsPhoto',ImageGroup);
}

addAssetMaintenanceDetails(add_maintainananceGroup:any){
  return this.http.post<any>(environment.ohhproAsset+'addAssetMaintenanceDetails',add_maintainananceGroup);
}
getAssetMaintenanceDetails(asset_details_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAssetMaintenanceDetails/'+asset_details_id);
}
editAssetMaintenanceDetails(edit_maintainananceGroup:any){
  return this.http.post<any>(environment.ohhproAsset+'editAssetMaintenanceDetails',edit_maintainananceGroup);
}

getAssetStatusCount(app_id:number){
   return this.http.get<any>(environment.newChanges+'getAssetStatusCount/'+app_id);
}
getGeneralLedger(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'getLedgerReport/'+app_id+'/'+year);
}
getTax(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'getInwardOutwardCost/'+app_id+'/'+year);
}
getInwardOutwardCostDetails_filterByDate(app_id:number,start_date:string,end_date:string){
  return this.http.get<any>(environment.newChanges+'getInwardOutwardCostDetails/'+app_id+'/'+start_date+'/'+end_date);
}
getInwardOutwardCostDetails_filterByYear(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'getInwardOutwardCostDetails/'+app_id+'/'+year);
}
editBankTransactionData(a:any)
{
  return this.http.post<any>(environment.razorpayUrl+'update/appBankDetails',a);
}
createAdminWithUser(data: any) {
  const url = 'lr/register/registerAdminWithUser';
  return this.http.post(environment.baseUrl1 + '' + url, data);
}
sendPhoneOTP(phoneNumber: number) {
  const url = 'lr/OTP/';
  return this.http.get(environment.baseUrl1 + '' + url + phoneNumber).pipe(tap(res => {
    // this.loader.stop();
  }, err => {
    console.log(err);
    // this.loader.stop();
  }));
}
veryfyPhoneOTP(admin: number) {
  const url = 'lr/verifyPhone/';
  return this.http.put(environment.baseUrl1 + '' + url + admin, '');
}
getAdmin() {
  const url = 'admin/alladmin';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}
createAdmin(data: any) {
  const url = 'lr/register/subAdmin';
  return this.http.post(environment.baseUrl1 + '' + url, data);
}
checkDuplicateUserMob(data: any) {
  const url = 'lr/profile/checkDuplicate';
  return this.http.post(environment.baseUrl1 + url, data).pipe(tap(res => {

  }, err => {

  }));
}
checkDuplicateUserEmail(data: any) {
  const url = 'lr/profile/checkDuplicate';
  return this.http.post(environment.baseUrl1 + url, data).pipe(tap(res => {

  }, err => {

  }));
}
ledgerReportDownload(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'ledger/getLedgerReportDetailsDownload/'+app_id+'/'+year);
}
trialBalanceDownload(app_id:number,year:number){
  return this.http.get<any>(environment.newChanges+'getTrialBalanceReport/'+app_id+'/'+year);
}
getParkingCount(app_id:number){
  return this.http.get<any>(environment.baseUrl2+'appartment/getParkingCount/'+app_id);
}

addPetrolling(data:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/addNightPatrollingLocation',data);
}
AddSchedule(data:any){
 return this.http.post<any>(environment.baseUrl2+'patrolling/addNightPatrollingSchedule',data)
}
getPetrollingDetails(appId:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getGeneratedQrcodeDetailsByApp/'+appId);
}
PostReports(form:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/getScanQRCodeStatusReportByDate',form);
}
getScheduleTime()
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getNightPatrollingScheduleName');
}
getScheduleTimeSlot(a:any,b:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getLocationTimeSchedule/'+b+'/'+a);
}
getScheduleTimeSlot1(a:any,b:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getTimeSchedule/'+b+'/'+a);
}
getPatrolling(appid:any){
  return this.http.get<any>(environment.baseUrl2+'patrolling/getNightPatrollingSchedule/'+appid);
}
getSingleExitlocation(appid:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getPatrollingLocationName/'+appid);
}
getBlockLocation(appid:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getBlockLocationName/'+appid)
}
postExistingLocation(form:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/addNightPatrollingQr',form);
}

getCounterBYlocid(locid:any)
{
  return this.http.get<any>(environment.baseUrl2+'patrolling/getLocationCounter/'+locid);
}
getIndivisualTimeBYschedule(form:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/getLocationNameByTime',form);
}

getPollingStatus(){
  return this.http.get<any>(environment.baseUrl2+'polling/admin/getAddStatusDetails');
}
getHouseByBlk(appid:any,blkid:any){
 return this.http.get<any>(environment.baseUrl2+'appartment/getHouseByAppBlk/'+appid+'/'+blkid);
}
generateTemp(data:any)
{
  return this.http.post<any>(environment.addtemplate+'template/add/addTemplate',data);
}
returnlistofinvoice(appid:any){
  return this.http.get<any>(environment.addtemplate+'template/get/getInvoiceTemplate/'+appid);
}
markaspaidapi(data:any){
  return this.http.post<any>(environment.addtemplate+'template/edit/updateInvoiceTemplatePaymentStatus',data);
}
AddAmcData(data:any){
  return this.http.post<any>(environment.addtemplate+'financial/vendor/addVendorAmc',data);
}
getVendorAcmList(appid:any){
 return this.http.get<any>(environment.addtemplate+'financial/vendor/getVendorAmcData/'+appid);
}
upDateVendorAmc(data:any){
  return this.http.post<any>(environment.addtemplate+'financial/vendor/updateVendorAmcActive',data);
}
updateEditVendorAmc(data:any){
  return this.http.post<any>(environment.addtemplate+'financial/vendor/updateVendorAmc',data);
}
getIndivisualDetailsAmc(assetid:any){
  return this.http.get<any>(environment.addtemplate+'financial/vendor/getAmcLogDetails/'+assetid);
}

genTempInvoice(data:any){
  return this.http.post<any>(environment.baseUrl2+'apart/pay/maintenanceInvoice',data);
}
GetFilterInvoice(appid:any,catid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMaintenanceInvoiceByCategory/'+appid+'/'+catid+'/'+year);
}
GetCategoryByFilter(appid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMaintenanceInvoiceCategory/'+appid+'/'+year);
}
getBlockByYear(appid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMaintenanceInvoiceBlock/'+appid+'/'+year);
}
getHouseByBlock(appid:any,blkid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMaintenanceInvoiceHouse/'+appid+'/'+blkid+'/'+year);
}
GetHouseFilter(appid:any,blkid:any,houseno:any,year:any,flrid:any){
 return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMaintenanceInvoiceHouseByAdmin/'+appid+'/'+blkid+'/'+flrid+'/'+houseno+'/'+year);
}

CashPayment(data:any){
  return this.http.post<any>(environment.addtemplate+'razorPay/invoice/cash/maintenanceAmount',data);
}
EditInvoice(data:any){
 return this.http.post<any>(environment.addtemplate+'razorPay/invoice/updateInvoiceMaitenance',data);
}
GetOpenData(data:any,appid:any){
 return this.http.get<any>(environment.baseUrl+'maintenance/manager/manager/getCompletedMaintenanceRecord/'+appid+'/'+data);
}
GetInprogressData(data:any,appid:any){
return this.http.get<any>(environment.baseUrl+'maintenance/manager/manager/getCompletedMaintenanceRecord/'+appid+'/'+data);
}
GetCompletedData(data:any,appid:any){
return this.http.get<any>(environment.baseUrl+'maintenance/manager/manager/getCompletedMaintenanceRecord/'+appid+'/'+data);
}
AdminReplayToEscalate(data:any){
  return this.http.put<any>(environment.assetUrl+'maintenance/customer/user/AdminResponseToEscalation',data);
}
DownloadPicture(data:any){
  return this.http.post<any>('http://dev01.ohhpro.com:9080/localMerchant/rest/market/getimage',data);
}
EditHouseMiscInvoice(data:any){
  return this.http.post<any>(environment.baseUrl2+'appartment/updateHouseMiscAmounts',data);
}
GetMiscInvo(appid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMiscInvoiceDashboard/'+appid+"/"+year);
}
GetHouseInvo(appid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/maintenance/getMaintenanceDashboard/'+appid+"/"+year);
}
GetMiscInvoicAnalysis(appid:any,catid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getMiscFilter/'+appid+'/'+catid+'/'+year);
}
GetMiscIndivisualData(appid:any,catid:any,year:any){
  return this.http.get<any>(environment.addtemplate+'razorPay/invoice/getAppInvoiceDetails/'+appid+'/'+catid+'/'+year);
}
GetAssetBookingDetails(appid:any,year:any){
  return this.http.get<any>(environment.razorpayUrl2+'user/getAssetBookingDetailsList/'+appid+'/'+year);
}

EditPatrollingLocationTagName(data:any){
  return this.http.post<any>(environment.assetUrl+'patrolling/editNightPatrollingQr',data);
}
GetAssetDetailsByAppIdAndYear(appid:any,year:any){
  return this.http.get<any>(environment.newChanges+'getAssetDetailsByFilter/'+appid+"/"+year);
}
GetInventoryDetailsByAppIdandYear(appid:any,year:any){
  return this.http.get<any>(environment.newChanges+'inventory/getInventoryDetailsByAppFilter/'+appid+'/'+year);
}
GetCategoryListForMiscInvoice(appid:any){
  return this.http.get<any>(environment.newChanges+'getGroupNameforMaintenanceinvoice/'+appid);
}
GetDuplicateCheck(data:any){
  return this.http.post<any>(environment.addtemplate+'razorPay/invoice/getInvoiceDuplicateCheck',data);
}
GetAttendanceVisitor(year:any,visitingmonth:any,appid:any,rvisitorid:any){
  return this.http.get<any>(environment.baseUrl2+'house/getAttendance/regularVisitor/'+year+"/"+visitingmonth+"/"+appid+"/"+rvisitorid);
}
GetIndivisualDetailsForDel(appid:any,phno:any){
 return this.http.get<any>(environment.baseUrl+'signaller/get/Signaller/getDeliveryBoyHouseByPhone/'+appid+'/'+phno);
}
updateUserTag(data:any){
 return this.http.post<any>(environment.baseUrl2+"user/updateUserTag",data);
}
getUserDetailsById(id:any){
  return this.http.get<any>(environment.baseUrl2+"user/get/User/"+id);
}
SendFeedback(data:any){
  return this.http.post<any>(environment.baseUrl2+"user/request/sendAnyEmail",data);
}
getPeoplesDocs(userid:any,appid:any,type:any){

  return this.http.get<any>(environment.baseUrl+"subAdmin/getIdProof/"+userid+"/"+type);
}
editSkillOfRegVisitor(data:any){
  return this.http.put<any>(environment.baseUrl+"subAdmin/updateRegularVisitorSkill",data);
}
editPictureofUser(data:any){
  return this.http.post<any>(environment.baseUrl+"subAdmin/editPics",data);
}
getNonRegistredUser(appid:any){
  return this.http.get<any>(environment.baseUrl2+"userNonApartment/getNonUserApartment/"+appid);
}
AddNonRegostredUser(data:any){
 return this.http.post<any>(environment.baseUrl2+"userNonApartment/addUserNonApartment",data);
}
editNonRegUser(data:any){
 return this.http.post<any>(environment.baseUrl2+"userNonApartment/updateUserNonApartment",data);
}
deleteNonUser(data:any){
  return this.http.post<any>(environment.baseUrl2+"userNonApartment/deleteUserNonApartment",data);
}
sentEmailToUser(data:any){

  return this.http.post<any>(environment.baseUrl2+"sendMail/SendEmailToUser",data);
}
getStreetVendorList(appid:any){
  return this.http.get<any>(environment.baseUrl2+"ApartmentStreetVendor/getVendorDetails/"+appid);
}
addStreetVendor(data:any){
  return this.http.post<any>(environment.baseUrl2+"ApartmentStreetVendor/addStreetVendor",data);
}
updateStreetVendor(data:any){
  return this.http.post<any>(environment.baseUrl2+"ApartmentStreetVendor/updateStreetVendor",data);
}
deleteStreetVendor(data:any){
  return this.http.post<any>(environment.baseUrl2+"ApartmentStreetVendor/deleteStreetVendor",data);
}
GetInfoOfRegVisitor(data:any){
return this.http.post<any>(environment.baseUrl2+"visitor/getHouseList",data);
}
deleteHouseRecord(data:any){
  return this.http.post<any>(environment.baseUrl2+"visitor/deleteVisitorFromHouse",data);
}
getVisitorAttendance(data:any){
  return this.http.post<any>(environment.baseUrl+"subAdmin/showAttendanceDetails",data);
}
getFilteredVisitorList(data:any){
  return this.http.post<any>(environment.baseUrl2+"communityService/getAllVisitorRecordByAppid",data);
}
filterByHouse(data:any){
  return this.http.post<any>(environment.baseUrl2+"communityService/myHome/filter/myVisitor",data);
}
getParkingData(data:any){
  return this.http.get<any>(environment.baseUrl2+"parking/getParkingList/"+data);
}
uploadShheet(data:any){
  return this.http.post<any>(environment.baseUrl2+"parking/add/addParkingList",data);
}

updateParkingLotInfo(data:any){
  return this.http.post<any>(environment.baseUrl2+"parking/edit/editParking",data);
}
getAttendanceVisitor(startDate:any,endDate:any,appid:any){
  return this.http.get<any>(environment.addtemplate+"document/getVisitorAttendanceDetails/"+startDate+"/"+endDate+"/"+appid);
}
getAttendanceVisitorNew(startDate:any,endDate:any,appid:any){
  return this.http.get<any>(environment.addtemplate+"document/getVisitorAttendanceDetailsProcedure/"+startDate+"/"+endDate+"/"+appid);
}
getHelpDeskList(appid:any){
  return this.http.get<any>(environment.baseUrl+"subAdmin/getHelpDeskList/"+appid);
}
getPatrollingReportsFilter(data:any){
  return this.http.post<any>(environment.baseUrl2+"patrolling/getGeneratedQrcodeDetailsByLocation",data);
}

SendQrTomail(data:any){
  return this.http.post<any>(environment.addtemplate+"document/getQRList",data);
}
CloseMaintenanceRequest(data:any){
  return this.http.put<any>(environment.baseUrl+"maintenance/manager/manager/completeMaintenanceNew",data);
}
getVisitorOverTimeDetails(visid:any,startdate:any,enddate:any){
  return this.http.get<any>(environment.addtemplate+"document/getVisitorOTDetails/"+visid+"/"+startdate+"/"+enddate);
}
getVisitorInOutDetails(visid:any,startdate:any,enddate:any){
  return this.http.get<any>(environment.addtemplate+"document/getVisitorInOutTime/"+visid+"/"+startdate+"/"+enddate);
}
getRegularVisitorCategory(appid:any){
  return this.http.get<any>(environment.baseUrl+"subAdmin/showRegularVisitorCategory/"+appid);
}

getAllAssetByAppid(app_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAssetDetailsNew/'+app_id);
}
getAllAminityByAppid(app_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAmenitiesDetailsNew/'+app_id);
}
addAssetDetailsNew(data){
  return this.http.post<any>(environment.newChanges+'addAssetDetailsNew',data);
}
addAmenityDetailsNew(data){
  return this.http.post<any>(environment.newChanges+'addAmenitiesDetailsNew',data);
}
getPerformanceLogStatus(app_id:number,asset_details_id:number){
  return this.http.get<any>(environment.performance+"getMaintenanceTrackerOnSpecificAsset/"+app_id+ "/"+asset_details_id);
}
getAssetRecord(app_id:number,asset_details_id:number){
  return this.http.get<any>(environment.razorpayUrl2+"user/getAssetBookingDetailsByAdminNew/"+app_id+ "/"+asset_details_id);
}
getAssetRecords(app_id:any){
  return this.http.get<any>(environment.razorpayUrl2+"user/getAssetBookingDetailsByAdminNew/"+app_id);
}
checkDuplicateNumber(data){
  return this.http.post<any>(environment.duplicate +'checkDuplicate',data);
}

getRegularVisitorDayRecord(appid:any,rvid:any,start_date:any,end_date:any){
  return this.http.get<any>(environment.regAttendance+"getRegularVisitorAttendanceByRVid/"+appid+"/"+rvid+"/"+start_date+"/"+end_date);
}
getAmenitiesSubscriptionType(){
  return this.http.get<any>(environment.razorpayUrl2+'getAmenitiesSubscriptionType')
}

getQueriesByUserId(chat_id:any){
  return this.http.get<any>(environment.baseUrl2+'fcm/getAllChatDetailsByChatId/'+chat_id)
}

getAllQueriesAndComplainByAppId(app_id:any,type:any){
  return this.http.get<any>(environment.baseUrl2+'fcm/getAllQueriesAndComplainByAppId/'+app_id+"/"+type)
}
getAmenityBookingDetails(app_id:number,amenities_details_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAmenitiesBookingDetailsByAppId/'+app_id+ "/"+amenities_details_id)
}
getAmenityBookingDetailsNew(app_id:number){
  return this.http.get<any>(environment.ohhproAsset+'getAmenitiesBookingDetailsByAppId/'+app_id)
}
editAmenitiesDetails(data){
  return this.http.post<any>(environment.razorpayUrl2+'user/updateAmenitiesDetails',data)
}
bankDetails(app_id:number,bank_account_id:number,year:number){

  return this.http.get<any>(environment.newChanges+'getBankTransactionById/'+app_id+ "/" +bank_account_id+ "/" +year)

}
sendChat(data:any){
  return this.http.post<any>(environment.chatapi+'queriesandcomplainchat',data);
}
getMonthFilterDashNew(a:any)
{
  return this.http.post<any>(environment.newChanges+'getLedgerReportFilterByMonthNew',a);
}



markAsSeen(data:any){
  return this.http.post<any>(environment.base+'ohhpro/rest/fcm/updateMarkAsSeen',data);

}

getRegularVisitorList(appid:any){
  return this.http.get<any>(environment.regAttendance+"getRegularVisitorAttendanceByAppIdAndMonth/"+appid);
}

allTicketList(appid:any,start_date:any,end_date:any){
  return this.http.get<any>(environment.baseUrl+'maintenance/manager/manager/getAllMaintenanceRecordByMonth/'+appid+'/'+start_date+'/'+end_date);
}
getSettelment(data:any){
  return this.http.post<any>(environment.addtemplate+'razorPay/settlement/getSettlement',data);
}
getIndView(data:any){
  return this.http.post<any>(environment.addtemplate+'razorPay/settlement/getSettlementIndivisual',data);
}
updateHandoverto(data:any){
  return this.http.post<any>(environment.ohhproAsset+'updateAssetHandOver',data);
}
viewHandoverto(id:any){
  return this.http.get<any>(environment.baseUrl2+'visitor/getAssetByVisitorId/'+id)
}
adminimage(data:any){
  return this.http.get<any>(environment.imgappartment+data)
}
getmaintenancefre(app_id:any){
  return this.http.get<any>(environment.baseUrl2+'apart/pay/getMaintenanceFrequency/'+app_id);
}
getMaintenanceFrequency(app_id:any){
  return this.http.get<any>(environment.baseUrl2+"apart/pay/getMaintenanceFrequency/"+app_id);
}

getCompleteMaintenanceData(data:any){
  return this.http.post<any>(environment.razorpayUrl+"maintenanceFinancialYear",data);
}
getGstinPanByAppId(data:any){
  return this.http.get<any>(environment.baseUrl2+"apart/pay/getMaintenanceDetailsInfo/"+data);
}
payduedateupdated(data:any){
  return this.http.post<any>(environment.houseDueUrl+"updatePayDueDate",data);
}
updateHouseSQFE(data:any){
  return this.http.post<any>(environment.baseUrl2+"appartment/updateHouseSquareFoot",data);
}

creditNote(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/addCreditNoteInfo",data);
}

getCreditNote(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/getCreditNotesByAppAndMonth",data);
}

checkCreditNote(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/checkCreditNoteForHouse",data);
}

getCreditNoteNew(data:any){
  return this.http.get<any>(environment.baseUrl2+"creditnote/getAllCreditNotesByAppId/"+data);
}

DeclineCreditNoteNew(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/declineCreditNote",data);
}
updateOwnerApproval(data:any){
  return this.http.post<any>(environment.baseUrl2+"tenant/updateAdminApproval",data);
}
checkDuplicateHouse(data:any){
  return this.http.post<any>(environment.baseUrl2+"appartment/checkHouseDuplicate",data)
}
CreditNoteApproveDetails(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/getCreditNoteApproveDetails",data);
}
getCreditNotesByApp(data:any){
  return this.http.post<any>(environment.baseUrl2+"creditnote/getCreditNotesByApp",data);
}

getAllFrequencyInfo(){
  return this.http.get<any>(environment.houseDueUrl+"getAllFrequencyInfo");
}

getAllInvoice(app_id:any,billing_date,frequency_type){
  return this.http.get<any>(environment.razorpayUrl+"maintenanceInvoiceNewOne/"+app_id+"/"+billing_date+"/"+frequency_type);
}
PostReportsFilter(form:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/getScanQRCodeStatusReportByDateFilter',form);
}

addAllRegVisitorDataList(data:any){
  return this.http.post<any>(environment.baseUrl+'subAdmin/add/regularVisitorList',data);
}



















//Regular Visitor
deleteRegularVisitorN(data:any) {
  return this.http.post<any>(environment.baseUrl +'subAdmin/delete/regularVisitor',data);
}

//facility manager
deleteFacilityN(data:any)
{
  return this.http.put<any>(environment.baseUrl + 'subAdmin/deleteMaintenanceManager',data);
}
//Management
deleteManagementCommiteeN(data:any)
{
  return this.http.post<any>(environment.baseUrl+'subAdmin/managementComite/delete/management',data);
}


deleteIncomeExpenseRecordByIdN(data:any){
  return this.http.post<any>(environment.baseUrl2+'apart/pay/delete/apartIncomeExp',data);
}
//parking
deleteParkingLotN(data:any){
  return this.http.post<any>(environment.baseUrl2+"parking/delete/parkingDetails",data);
}
deleteApartmentAssetGroupN(data:any){
  return this.http.post<any>(environment.ohhproAsset+'delete/asset',data);
}

//Complain
deleteTheIndivisualChatN(data:any){
  return this.http.post<any>(environment.base+'ohhpro/rest/fcm/deleteIndivisualChatByChatId',data);
}
//Notice
deleteNoticeN(data:any) {
  return this.http.post<any>(environment.baseUrl2+'notice/delete/Notice',data);
}
// patrolling
deleteScheduleIdN(data:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/deleteLocationSchedule',data);
}
deleteCounterN(data:any)
{
  return this.http.post<any>(environment.baseUrl2+'patrolling/deleteLocationTag',data);
}
// patrolling
deleteQrCodeTagN(data:any){
  return this.http.post<any>(environment.baseUrl2+"patrolling/deleteLocationTagByQrlocationId",data);
}
//Polling
deleteApolingbyPollingIdN(data:any){
  return this.http.post<any>(environment.baseUrl2+"polling/delete/polling",data);
}
//Polling
deleteCandidateofApollingN(data:any){

  return this.http.post<any>(environment.baseUrl2+"polling/delete/pollingCandidate",data);
}

//Assorted
DeleteInvoiceN(data:any){
  return this.http.post<any>(environment.addtemplate+'razorPay/invoice/deleteMaintenanceInvoice',data);
}

deleteApartmentLedgerGroupN(data:any){
  return this.http.post<any>(environment.newChanges+'ledger/deleteApartmentLedgerGroup',data);
}

deleteApartmentLedgerN(data:any){

  return this.http.post<any>(environment.newChanges+'ledger/deleteApartmentLedger',data);
}
//ledger
deleteApartmentLedgerEntryN(data:any){
  return this.http.post<any>(environment.newChanges+'ledger/deleteApartmentLedgerEntry',data);
}


//inventory
deleteApartmentInventoryGroupN(data:any){
  return this.http.post<any>(environment.newChanges+'ledger/deleteApartmentInventoryGroup',data);
}
//inventory
deleteApartmentInventoryN(data:any){
  return this.http.post<any>(environment.newChanges+'inventory/deleteApartmentInventory',data);
}

//inventory
deleteApartmentInventoryDetailsN(data:any){
  return this.http.post<any>(environment.newChanges+'inventory/deleteApartmentInventoryDetails',data);
}

//asset
deleteAssetCategoryN(data:any){
  return this.http.post<any>(environment.newChanges+'deleteAssetCategory',data);
}

deleteAssetN(data:any){
  return this.http.post<any>(environment.newChanges+'deleteAssetDetails',data);
}







// deleteApolingbyPollingId(id:any){
//   const url = 'polling/delete/polling/';
//   return this.http.get(environment.baseUrl2 + url + id);
// }
// deleteCandidateofApolling(id:any){
//   const url='polling/delete/pollingCandidate/';
//   return this.http.get(environment.baseUrl2+url+id);
// }
 // deleteRegularVisitor(a:number,appID: number) {
  //   return this.http.get<any>(environment.baseUrl +'subAdmin/delete/regularVisitor/'+ a +'/'+appID);
  // }
  // deleteNotice(id: number) {
  //   const url = 'notice/delete/Notice/';
  //   return this.http.get<any[]>(environment.baseUrl2 + url + id);
  // }
// deleteIncomeExpenseRecordById(record_id){
//   const url = 'apart/pay/delete/apartIncomeExp/';
//   return this.http.delete(environment.baseUrl2 + url + record_id);
//   // http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/apart/pay/delete/apartIncomeExp/8
// }
// deleteFacility(a:number,obj)
// {
//   return this.http.put<any>(environment.baseUrl + 'subAdmin/deleteMaintenanceManager/'+a, obj);
// }
// deleteApartmentInventoryGroup(inventory_group_id:number){
//   return this.http.delete<any>(environment.newChanges+'ledger/deleteApartmentInventoryGroup/'+inventory_group_id);
// }
// deleteApartmentAssetGroup(asset_category_id:number){
//   return this.http.get<any>(environment.ohhproAsset+'delete/asset/'+asset_category_id);
// }
// deleteApartmentInventory(inventory_id:number){
//   return this.http.delete<any>(environment.newChanges+'inventory/deleteApartmentInventory/'+inventory_id);
// }
// deleteApartmentInventoryDetails(inventory_details_id:number,InventoryPics:string){
//   return this.http.delete<any>(environment.newChanges+'inventory/deleteApartmentInventoryDetails/'+inventory_details_id+'/'+InventoryPics);
// }
// deleteAssetCategory(asset_category_id:number){
//   console.log("hi2",asset_category_id);
//   return this.http.delete<any>(environment.newChanges+'deleteAssetCategory/'+asset_category_id);
// }
// deleteAsset(id:number,ap:string,aip:string){
//   return this.http.delete<any>(environment.newChanges+'deleteAssetDetails/'+id+'/'+ap+'/'+aip);
// }
// deleteApartmentLedgerGroup(app_id:number,ledger_group_id:number){
//   return this.http.delete<any>(environment.newChanges+'ledger/deleteApartmentLedgerGroup/'+app_id+'/'+ledger_group_id);
// }
// deleteApartmentLedger(ledger_id:number){
//   console.log("hi1",ledger_id);
//   return this.http.delete<any>(environment.newChanges+'ledger/deleteApartmentLedger/'+ledger_id);
// }
// deleteApartmentLedgerEntry(apartment_ledger_id:number,LedgerPics:string){
//   return this.http.delete<any>(environment.newChanges+'ledger/deleteApartmentLedgerEntry/'+apartment_ledger_id+'/'+LedgerPics);
// }
// deleteManagementCommitee(id:any)
// {
//   return this.http.get<any>(environment.baseUrl+'subAdmin/managementComite/delete/management/'+id);
// }
// deleteCounter(locid:any,counter:any)
// {
//   return this.http.delete<any>(environment.baseUrl2+'patrolling/deleteLocationTag/'+locid+'/'+counter);
// }
// deleteScheduleId(schid:any)
// {
//   return this.http.delete<any>(environment.baseUrl2+'patrolling/deleteLocationSchedule/'+schid);
// }
// DeleteInvoice(invoicepayid:any){
//   return this.http.delete<any>(environment.addtemplate+'razorPay/invoice/deleteMaintenanceInvoice/'+invoicepayid);
// }
// deleteParkingLot(parking_id:any){
//   return this.http.delete<any>(environment.baseUrl2+"parking/delete/parkingDetails/"+parking_id);
// }
// deleteQrCodeTag(locid:any,qrlocid:any){
//   return this.http.delete<any>(environment.baseUrl2+"patrolling/deleteLocationTagByQrlocationId/"+locid+"/"+qrlocid);
// }
// deleteTheIndivisualChat(chat_details_id:any){
//   return this.http.get<any>(environment.base+'ohhpro/rest/fcm/deleteIndivisualChatByChatId/'+chat_details_id);
// }














}
